import menuMsg from './timer';
import profileMsg from './profile';
import teamsMsg from './teams';
import scoreboardMsg from './scoreboard';
import generalMsg from './general';
import settingsMsg from './settings';
import tournamentMsg from './tournaments';
import authMsg from './auth';
import vuetifyMsg from './vuetify';
import roundsMsg from './rounds';
import errorMsg from './error';
import tablesMsg from './tables';
import scoresheetMsg from './scoresheet';
import refereeResultsMsg from './refereeResults';
import usersMsg from './users';
import remarksMsg from './remarks';
import exportsMsg from './exports';
import scheduleMsg from './schedule';
import timerMsg from './timer';
export default {
    menu: menuMsg,
    profile: profileMsg,
    teams: teamsMsg,
    scoreboard: scoreboardMsg,
    general: generalMsg,
    settings: settingsMsg,
    tournament: tournamentMsg,
    auth: authMsg,
    rounds: roundsMsg,
    error: errorMsg,
    tables: tablesMsg,
    scoresheet: scoresheetMsg,
    $vuetify: vuetifyMsg,
    refereeResults: refereeResultsMsg,
    users: usersMsg,
    remarks: remarksMsg,
    exports: exportsMsg,
    schedule: scheduleMsg,
    timer: timerMsg,
    '10': '10',
    '25': '25',
    '50': '50',
    '100': '100'
};
