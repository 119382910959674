export default {
    logout: 'Uitloggen',
    login: 'Login',
    dashboard: 'Dashbord',
    profile: 'Profiel',
    users: 'Gebruikers',
    admin: 'Admin',
    actions: 'Acties',
    teams: 'Teams',
    judge: 'Jury',
    robotGame: 'Robotwedstrijd',
    scoreboard: 'Scoreboard',
    setup: 'Setup',
    schedule: 'Schema',
    userSettings: 'Gebruikersinstellingen',
    settings: 'Instellingen',
    tournaments: 'Toernooien',
    tables: 'Tafels',
    rounds: 'Rondes',
    refereeResults: 'Wedstrijdresultaat',
    wizard: 'Wizard',
    remarks: 'Opmerkingen',
    export: 'Exports',
    jury: 'Jury'
};
