import masterpieceMsg from "./masterpiece";
import generalMsg from "./scoresheet_general"
import submergedMsg from "./submerged";

export default {
    general: generalMsg,
    masterpiece: masterpieceMsg,
    submerged: submergedMsg,

};
