export default {
    M00_long_name: 'UITRUSTINSINSPECTIE',
    M00_requirements: '',
    M00_description: '',
    M00_1_desc:
        '(Voor de wedstrijd) De robot en alle uitrusting past volledig in één startgebied en zit onder een hoogtelimiet van 305mm:',
    M01_long_name: 'KORAALKWEKERIJ',
    M01_requirements: 'Geen uitrusting-regel',
    M01_description: '',
    M01_1_desc: 'De koraalboom hangt aan de koraalboomsteun',
    M01_2_desc: 'De onderkant van de koraalboom zit in de houder',
    M01_3_desc: 'De koraalknoppen zijn omhoog geklapt',
    M02_long_name: 'HAAI',
    M02_requirements: '',
    M02_description: '',
    M02_1_desc: 'De haai raakt de grot niet meer aan',
    M02_2_desc: 'De haai raakt de mat aan en is ten minste gedeeltelijk in het haaileefgebied',
    M03_long_name: 'KORAALRIF',
    M03_requirements: '',
    M03_description: '',
    M03_1_desc: 'Het koraalrif is omhoog geklapt en raakt de mat niet aan',
    M03_2_desc: 'Het aantal rifsegmenten dat rechtop staat, buiten het thuisgebied en de mat raakt',
    M04_long_name: 'DUIKER',
    M04_requirements: '',
    M04_description: '',
    M04_1_desc: 'De duiker raakt de koraalkwekerij niet meer aan',
    M04_2_desc: 'De duiker hangt aan de koraalrifsteun',
    M05_long_name: 'HENGELVIS',
    M05_requirements: '',
    M05_description: '',
    M05_1_desc: 'De hengelvis zit vergrendeld in het scheepswrak',
    M06_long_name: 'HIJS DE MAST',
    M06_requirements: '',
    M06_description: '',
    M06_1_desc: 'De mast van het scheepswrak is volledig opgehesen',
    M07_long_name: "KRAKEN'S SCHAT",
    M07_requirements: '',
    M07_description: '',
    M07_1_desc: 'De schatkist is volledig buiten het krakennest',
    M08_long_name: 'KUNSTMATIG LEEFGEBIED',
    M08_requirements: '',
    M08_description: '',
    M08_1_desc: 'Aantal kunstmatig leefgebiedsegmenten volledig vlak en rechtopstaand',
    M09_long_name: 'ONVERWACHTE ONTMOETING',
    M09_requirements: '',
    M09_description: '',
    M09_1_desc: 'Het onbekende dier is losgelaten',
    M09_2_desc: 'Het onbekende dier is ten minste gedeeltelijk in de koude submariene bron',
    M10_long_name: 'STUUR DE DUIKBOOT OVER',
    M10_requirements: '',
    M10_description: '',
    M10_1_desc: 'De gele vlag van jouw team is naar beneden',
    M10_2_desc: 'De duikboot is duidelijk dichter bij het tegenoverliggende veld',
    M11_long_name: 'SONARDETECTIE',
    M11_requirements: '',
    M11_description: '',
    M11_1_desc: 'Eén walvis is onthuld',
    M11_2_desc: 'Tweede walvis zijn onthuld',
    M12_long_name: 'VOED DE WALVIS',
    M12_requirements: '',
    M12_description: '',
    M12_1_desc: 'Het aantal krill dat ten minste gedeeltelijk in de mond van de walvis is',
    M13_long_name: 'VAARROUTES WIJZIGEN',
    M13_requirements: '',
    M13_description: '',
    M13_1_desc: 'Het schip bevindt zich in de nieuwe vaarroute en raakt de mat aan',
    M14_long_name: 'STAALVERZAMELING',
    M14_requirements: '',
    M14_description: '',
    M14_1_desc: 'Het waterstaal is volledig buiten het waterstaalgebied',
    M14_2_desc: 'Het zeebodemstaal raakt de zeebodem niet meer aan',
    M14_3_desc: 'Het planktonstaal raakt het kelpwoud niet meer aan',
    M14_4_desc: 'Het aantal onderdelen van de drietand die het scheepswrak niet meer raken',
    M15_long_name: 'ONDERZOEKSSCHIP',
    M15_requirements: '',
    M15_description: '',
    M15_1_desc:
        'Aantal stalen, drietandonderdelen of schatkist ten minste gedeeltelijk in het vrachtruim' +
        'van het onderzoeksschip',
    M15_2_desc: 'De havensluiting zit ten minste gedeeltelijk in de schipslus',
};
