export default {
    M00_long_name: 'INSPECTION D’ÉQUIPEMENT',
    M00_requirements: '',
    M00_description: '',
    M00_1_desc: 'All team equipment fits in one launch area and under 12 in. (305 mm):',
    M01_long_name: 'CORAL NURSERY',
    M01_requirements: 'No supporting structures',
    M01_description: '',
    M01_1_desc: 'The coral tree is hanging on the coral tree support',
    M01_2_desc: 'The bottom of the coral tree is in its holder',
    M01_3_desc: 'The coral buds are flipped up',
    M02_long_name: 'SHARK',
    M02_requirements: '',
    M02_description: '',
    M02_1_desc: 'The shark is no longer touching the cave',
    M02_2_desc: 'The shark is no longer touching the mat at least partly in the shark habitat',
    M03_long_name: 'CORAL REEF',
    M03_requirements: '',
    M03_description: '',
    M03_1_desc: 'The coral reef is flipped up, not touching the mat',
    M03_2_desc: 'Number of the reef segments standing upright, outside of home, and touching the mat',
    M04_long_name: 'SCUBA DIVER',
    M04_requirements: '',
    M04_description: '',
    M04_1_desc: 'The scuba diver is no longer touching the coral nursery',
    M04_2_desc: 'The scuba diver is hanging on the coral reef support',
    M05_long_name: 'ANGLER FISH',
    M05_requirements: '',
    M05_description: '',
    M05_1_desc: 'The angler fish is latched within the shipwreck',
    M06_long_name: 'RAISE THE MAST',
    M06_requirements: '',
    M06_description: '',
    M06_1_desc: "The shipwreck's mast is completely raised",
    M07_long_name: "KRAKEN'S TREASURE",
    M07_requirements: '',
    M07_description: '',
    M07_1_desc: "The treasure chest is completely outside the kraken's nest",
    M08_long_name: 'ARTIFICIAL HABITAT',
    M08_requirements: '',
    M08_description: '',
    M08_1_desc: 'Number of artificial habitat stack segments completely flat and upright',
    M09_long_name: 'UNEXPECTED ENCOUNTER',
    M09_requirements: '',
    M09_description: '',
    M09_1_desc: 'The unknown creature is released',
    M09_2_desc: 'The unknown creature is at least partly in the cold seep',
    M10_long_name: 'SEND OVER THE SUBMERSIBLE',
    M10_requirements: '',
    M10_description: '',
    M10_1_desc: "Your team's yellow flag is down",
    M10_2_desc: 'The submersible is clearly closer to the opposing field',
    M11_long_name: 'SONAR DISCOVERY',
    M11_requirements: '',
    M11_description: '',
    M11_1_desc: 'One whale revealed',
    M11_2_desc: 'Second whale revealed',
    M12_long_name: 'FEED THE WHALE',
    M12_requirements: '',
    M12_description: '',
    M12_1_desc: "Number of krill at least partly in the whale's mouth",
    M13_long_name: 'CHANGING SHIPPING LANES',
    M13_requirements: '',
    M13_description: '',
    M13_1_desc: 'The ship is in the new shipping lane, touching the mat',
    M14_long_name: 'SAMPLE COLLECTION',
    M14_requirements: '',
    M14_description: '',
    M14_1_desc: 'The water sample is completely outside the water sample area',
    M14_2_desc: 'The seabed sample is no longer touching the seabed',
    M14_3_desc: 'The plankton sample is no longer touching the kelp forest',
    M14_4_desc: 'Number of trident pieces no longer touching the shipwreck',
    M15_long_name: 'RESEARCH VESSEL',
    M15_requirements: '',
    M15_description: '',
    M15_1_desc:
        'Number of samples, trident part(s), or treasure chest at least partly in the research' +
        ' vessel’s cargo area:',
    M15_2_desc: 'Le loquet du port se trouve au moins en partie dans la boucle du navire de recherche',
};
