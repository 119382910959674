export default {
    title: 'Général',
    add: 'Ajouter',
    close: 'Fermer',
    save: 'Enregistrer',
    edit: 'Modifier',
    show: 'Afficher',
    delete: 'Supprimez',
    actions: 'Actions',
    active: 'Actif',
    search: 'Chercher',
    update: 'Mise à jour',
    language: 'Langue',
    upload: 'Charger',
    score: 'Score',
    created_at: 'Créé le',
    priority: 'Priorité',
    download: 'Télécharger',
    referee: 'Arbitre',
    points: 'points',
    table: 'Table',
    judgeSession: 'Jury Session',
    match: 'Match de Robot'
};