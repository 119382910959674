export default {
    title: 'Algemeen',
    add: 'Toevoegen',
    close: 'Sluiten',
    save: 'Opslaan',
    edit: 'Aanpassen',
    show: 'Bekijken',
    delete: 'Verwijderen',
    actions: 'Acties',
    active: 'Actief',
    search: 'Zoeken',
    update: 'Updaten',
    language: 'Taal',
    upload: 'Uploaden',
    score: 'Score',
    created_at: 'Aangemaakt op',
    priority: 'Prioriteit',
    download: 'Download',
    referee: 'Scheidsrechter',
    points: 'punten',
    table: 'Tafel',
    judgeSession: 'Jury Sessie',
    match: 'Robot Wedstrijd'
};